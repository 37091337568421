import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import BodyContent from "../components/body-content/body-content";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import CookieUtils from '../utils/cookie-utils';

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  return (
    <LayoutInner>
      <SEO title="Overcoming Nerves" keywords={[`rocksteady`]} />
      <SecondaryNavBar
        product="generic"
        title="Rocksteady Video"
        titlePath="/"
        titleActive={true}
        links={[]}
      />

      <Container>
        <BodyContent>
          <div className="videoWrapper">
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/518654505${urlParams}`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Video"
            />
          </div>
        </BodyContent>
      </Container>
    </LayoutInner>
  );
}

export default Page;
